import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'

// 會員中心我的發布

// 取得AES Key
export function getAESKey() {
  return request({
    url: '/api/aes_key',
    method: 'get'
  })
}

// 新增 發布文章
export function postArticle (data) {
  return request({
    url: '/api/users/me/articles',
    method: 'post',
    data: getFormData(data)
  })
}

// 修改 發布文章
export function editArticle (data, id) {
  return request({
    url: `/api/users/me/articles/${id}`,
    method: 'post',
    data: getFormData(data)
  })
}

// 取得 發布文章
export function getArticles (params) {
  return request({
    url: '/api/users/me/articles',
    method: 'get',
    params: params
  })
}

// 取得單一 發布文章
export function getArticle (id) {
  return request({
    url: `/api/users/me/articles/${id}`,
    method: 'get'
  })
}

// 刪除 發布文章 (一個或多個)
export function deleteArticle (id) {
  return request({
    url: `/api/users/me/articles/${id}`,
    method: 'delete'
  })
}

// 刪除 發布文章圖片
export function deleteArticlePhoto (id) {
  return request({
    url: `/api/users/me/articles/${id}/photo`,
    method: 'delete'
  })
}

// Editor上傳圖片
export function postUserEditorPics (data) {
  return request({
    url: '/api/ckeditor/upload',
    method: 'post',
    data: getFormData(data)
  })
}