<template>
  <div class="manage-container" :class="!isMobile ? 'card-container': ''">
    <div class="title-container">
      <h2>內容管理</h2>
    </div>
    <div class="content-container">
      <div v-if="articles.length == 0" class="no-article-container">
        <img class="no-article-image" src="@/assets/member/no-article.png" />
        <div class="no-article-text">你没有發佈內容噢～</div>
      </div>
      <div v-else>
        <div 
          class="content-item" 
          v-for="(item, index) in articles" 
          :key="index">
          <div class="info-block" @click="handleDetail(item.id)">
            <div class="title"> {{ item.title }}</div>
            <div class="content" v-html="item.content"></div>
          </div>
          <div class="btns-block">
            <div @click="handleComfirmDeleteArticle(item.id)">
              <img src="@/assets/member/delete-icon.png">
            </div>
            <div :class="item.state === 'deleted' ? 'hide-btn' : ''" @click="handleEditArticle(item.id)">
              <img src="@/assets/member/edit-icon.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/device"
import { contentLimit } from "@/utils/contentLimit"
import { goPage, goPageBlank } from '@/utils/route'
import { getArticles, deleteArticle } from '@/api/client/articles'
import resStatus from '@/constants/resStatus'

export default {
  data () {
    return {
      articleList: [],
      query: {
        page: 1,
        pageSize: 50
      },
    }
  },
  created () {
    this.init()
  },
  computed: {
    articles: {
      get () {
        return contentLimit(this.articleList)
      },
      set (data) {
        this.articleList = data
      }
    },
    isMobile () {
      return isMobile()
    }
  },
  methods: {
    init () {
      this.handleGetArticles()
    },
    handleGetArticles () {
      getArticles(this.query)
      .then(res => {
        switch (res.status) {
          case resStatus.OK:
            if (res.data.length > 0) {
              this.articles = res.data
              // this.totalPages = res.totalPages
            }
            break
          default:
            // this.$message.error(res.message)
            let msg = res.message ? res.message : '發生错误'
            this.$message.error(msg)
        }
      })
    },
    handleComfirmDeleteArticle (id) {
      this.$confirm('此操作将永久删除该则文章, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleDeleteArticle(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      })
    },
    handleDeleteArticle (id) {
      deleteArticle(id)
      .then(res => {
        switch (res.status) {
          case resStatus.OK:
            if (this.articleList.length > 0) {
              const articleIdx = this.articleList.findIndex( item => item.id === id)
              let curList = this.articleList
              curList.splice(articleIdx, 1)
              this.articles = curList
              this.$message.success("删除成功")
            }
            break
          default:
            // this.$message.error(res.message)
            let msg = res.message ? res.message : '發生错误'
            this.$message.error(msg)
        }
      })
    },
    handleEditArticle (id) {
      goPage({
        name: 'MemberPostArticle',
        query: {id: id}
      })
    },
    handleDetail (id) {
      goPageBlank({
        name: 'LotteryInfoDetail',
        query: {type: 'user_article', id: id}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.manage-container {
  min-height: inherit;
  background-color: #FFF;
  .title-container {
    padding: 18px 30px;
    text-align: left;
    border-bottom: 1px solid #E0E0E0;
    h2 {
      font-weight: 500;
      font-size: 21px;
      color: #000000;
    }
  }
  .content-container {
    .no-article-container{
      padding: 67px 0 164px;
      .no-article-image {
        width: 220px;
        height: auto;
        margin: 0 0 23px;
      }
      .no-article-text {
        font-size: 18px;
        color: #000;
      }
    }
    .content-item {
      position: relative;
      width: 100%;
      padding: 15px 30px;
      margin: 0;
      text-align: left;
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% - 60px);
        height: 0;
        bottom: 0;
        left: 30px;
        border-bottom: 1px #E0E0E0 solid;
      }
      &:last-child {
        &::after {display: none;}
      }
      &:hover {
        background: #F3F9FF;
        .btns-block {
          display: block;
        }
        .info-block {
          max-width: calc(100% - 145px);
        }
      }
      .btns-block {
        display: none;
        float: right;
        margin: 13px 0 0 55px;
        div {
          display: inline-block;
          cursor: pointer;
          & img {
            width: 24px;
            height: 24px;
            margin-right: 20px;
          }
        }
        .hide-btn {
          visibility: hidden;
        }
      }
      .info-block {
        display: inline-block;
        width: 100%;
        max-width: 100%;
        cursor: pointer;
        .title {
          max-width: 100%;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          margin-bottom: 7px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .content {
          max-width: 100%;
          font-size: 14px;
          line-height: 20px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: #4F4F4F;
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .manage-container {
    .title-container {
      padding: 18px 30px;
      text-align: center;
      border-bottom: none;
      background: #56CCF2;
      h2 {
        color: #FFFFFF;
      }
    }
    .content-container {
      .no-article-container{
        padding: 73px 0 0;
        .no-article-image {
          width: 220px;
          height: auto;
          margin: 0 0 23px;
        }
        .no-article-text {
          font-size: 18px;
          color: #000;
        }
      }
      .content-item {
        padding: 15px;
        &::after {
          width: calc(100% - 30px);
          left: 15px;
        }
        .btns-block {
          display: block;
          margin: 20px 0 0 10px;
          div {
            & img {
              margin-right: 15px;
            }
          }
        }
        .info-block {
          display: inline-block;
          max-width: calc(100% - 100px);
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .manage-container {
    .title-container {
      padding: 18px 30px;
      text-align: center;
      border-bottom: none;
      background: #56CCF2;
      h2 {
        color: #FFFFFF;
      }
    }
    .content-container {
      .no-article-container{
        padding: 73px 0 0;
        .no-article-image {
          width: 220px;
          height: auto;
          margin: 0 0 23px;
        }
        .no-article-text {
          font-size: 18px;
          color: #000;
        }
      }
      .content-item {
        padding: 15px;
        &::after {
          width: calc(100% - 30px);
          left: 15px;
        }
        .btns-block {
          display: block;
          margin: 20px 0 0 10px;
          div {
            & img {
              margin-right: 15px;
            }
          }
        }
        .info-block {
          display: inline-block;
          max-width: calc(100% - 100px);
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
  
}
</style>